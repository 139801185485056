import React, { useRef, useState } from "react"
// import * as S from './home-intro.styles'
import * as S from "./advisory-services.styles"
// import {BgImg} from "./home-intro.styles";
import Slider from "react-slick"
import { graphql, useStaticQuery } from "gatsby"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import SectionTitle from "../../components/section-title/section-title.component"
// import AdviseCard from "../../components/advise-card/advise-card.component";
import ArrowPrev from "../../components/arrow-prev/arrow-prev.component"
import ArrowNext from "../../components/arrow-next/arrow-next.component"
import { Container, useMediaQuery } from "@mui/material"
import AdviseCard from "../../components/advise-card/advise-card.component"

const AdvisoryServices = ({ topTitle, title, services }) => {
  let slider = useRef(null)
  const [sliderCount, setSliderCount] = useState(1)
  const totalSlides = services.length

  const matches = useMediaQuery(theme => theme.breakpoints.up("sm"))

  const staticQuery = useStaticQuery(graphql`
    query {
      dotsBg: file(relativePath: { eq: "dots-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  const settings = {
    infinite: true,
    arrows: false,
    speed: 500,
    arrow: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    // centerMode: true,
    adaptiveHeight: true,

    customPaging: () => <S.CustomDot />,
    appendDots: dots => (
      <Container>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </Container>
    ),
    dots: true,
    // centerPadding: 100,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          // slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          // slidesToScroll: 1,
          infinite: true,
          centerMode: false,
          dots: true,
        },
      },
    ],
    beforeChange: (current, next) => setSliderCount(next + 1),
  }

  const next = () => {
    slider.slickNext()
  }

  const previous = () => {
    slider.slickPrev()
  }
  return (
    <S.Wrapper id="advise">
      <S.StyledBgImage img={staticQuery.dotsBg} />

      {/*<HomeWhat title={props.whatWeDoSection.title} steps={steps}/>*/}
      <S.CustomContainer>
        <Container>
          <S.TopTitle>{topTitle}</S.TopTitle>
          <SectionTitle subhead="services">{title}</SectionTitle>
          {matches && (
            <S.Controls>
              <S.SliderButton onClick={previous} className="prev">
                <ArrowPrev strokeColor="#3F7EE3" />
              </S.SliderButton>

              <S.SliderButton onClick={next} className="next">
                <ArrowNext strokeColor="#FFFFFF" />
              </S.SliderButton>
            </S.Controls>
          )}
        </Container>

        <S.SliderWrapper>
          <Slider {...settings} ref={c => (slider = c)}>
            {services.map((service, index) => (
              <AdviseCard {...service} key={`service=${index}`} />
            ))}
          </Slider>
        </S.SliderWrapper>
      </S.CustomContainer>
    </S.Wrapper>
  )
}

export default AdvisoryServices
