import styled from "@emotion/styled"
import { Container, Typography } from "@mui/material"

export const Wrapper = styled.div`
  width: 100%;
  min-height: 650px;
  max-height: 850px;
  height: calc(100vh - 170px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  color: white;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  //background-image: url(${({ bgImg }) => bgImg});
  background-size: cover;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &:after {
    content: "";
    position: absolute;
    height: 200px;
    bottom: 0;
    background: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111111 100%);
    left: 0;
    z-index: 2;
    right: 0;
  }
`

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
`

export const HeroTextContainer = styled(Container)`
  position: relative;
  z-index: 3;
  padding-bottom: 9em;
`

export const Title = styled(Typography)`
  font-size: 2rem;
  max-width: 555px;
  font-weight: 600;
  margin-bottom: 0.5em;
  font-family: ${({ theme }) => theme.fonts.secondary};
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 3.5rem;
  }
`
