import styled from "@emotion/styled"
import { Container, Typography } from "@mui/material"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Section = styled(SectionWrapper)`
  background-color: #fafafa;
`

export const CustomContainer = styled(Container)`
  position: relative;

  img {
    max-width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    top: -10em;
  }
  h2 {
    margin-bottom: 0.7em;
  }

  li {
    margin-bottom: 0.5em;
    font-size: 1rem;
  }
`

export const Title = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 600;
  font-family: ${({ theme }) => theme.fonts.secondary};
  line-height: 1.5;
  letter-spacing: 0.00938em;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 2.5rem;
  }
`

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  margin-bottom: 1em;
  font-weight: 600;
`

export const Paragraph = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.secondary};
`

export const Content = styled.div`
  max-width: 390px;
  margin: auto;
`
