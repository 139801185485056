import React from "react"

import * as S from "./about-us.styles"
import SectionTitle from "../../components/section-title/section-title.component"
import { Grid, Typography, Container } from "@mui/material"

const AboutUs = ({ title, content, partners }) => {
  return (
    <S.Wrapper>
      <Container>
        <S.IntroGrid container>
          <Grid item md={4}>
            <SectionTitle colorInherit>{title}</SectionTitle>
          </Grid>
          <Grid item md={8}>
            <Typography>{content}</Typography>
          </Grid>
        </S.IntroGrid>

        <Grid container spacing={2}>
          {partners?.map(({ image, name, role }, index) => (
            <Grid item xs={12} md={6} key={`partner-${index}`}>
              <S.ImageWrapper>
                <S.ImageColumn img={image} alt={name} paddingPercentage="100" />
                <S.ImageCaption>
                  <Typography>
                    <b>{name}</b>
                  </Typography>
                  <Typography>{role}</Typography>
                </S.ImageCaption>
              </S.ImageWrapper>
            </Grid>
          ))}
          <Grid item container spacing={2} xs={12} md={12}>
            {partners?.map(({ features }) =>
              features?.map(({ title, content }, index) => (
                <Grid item xs={12} sm={3} key={`features-${index}`}>
                  <S.InfoTitle>{title}</S.InfoTitle>
                  <S.InfoContent>{content}</S.InfoContent>
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default AboutUs
