import React from "react";

const ArrowNext = ({strokeColor = '#3F7EE3'}) => (
    <svg width="9px" height="16px" viewBox="0 0 9 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="UI-for-Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="keel-info---02---1" transform="translate(-240.000000, -208.000000)" stroke={strokeColor}
               strokeWidth="2">
                <g id="next" transform="translate(220.000000, 192.000000)">
                    <polyline id="icon"
                              transform="translate(24.000000, 24.000000) rotate(-90.000000) translate(-24.000000, -24.000000) "
                              points="17 21 24 27 31 21"></polyline>
                </g>
            </g>
        </g>
    </svg>
)

export default ArrowNext;