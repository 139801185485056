import React from "react"

import * as S from "./interim-leadership.styles"
import SectionTitle from "../../components/section-title/section-title.component"
import { Typography, Grid } from "@mui/material"
import parse from "html-react-parser"
import CustomImage from "../../components/custom-image/custom-image.component"

const InterimLeadership = ({ bgImage, title, subtitle, content, features }) => {
  return (
    <S.Wrapper id="lead">
      <S.StyledBgImage img={bgImage} />

      <S.CustomContainer>
        {/*<SectionTitle>{props.interimSection.title}</SectionTitle>*/}
        <S.IntroGrid container spacing={6}>
          <Grid item md={5}>
            <SectionTitle>{title}</SectionTitle>
          </Grid>
          <Grid item md={7}>
            {parse(content)}
          </Grid>
        </S.IntroGrid>

        <Grid container spacing={2}>
          {features?.map(({ icon, text }, index) => (
            <Grid item xs={12} sm={6} md={3} key={`features-image-${index}`}>
              <S.DarkBox>
                <CustomImage img={icon} />
                <Typography>{text}</Typography>
              </S.DarkBox>
            </Grid>
          ))}
        </Grid>
      </S.CustomContainer>
    </S.Wrapper>
  )
}

export default InterimLeadership
