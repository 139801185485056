import React from "react"

import * as S from "./implementation-services.styles"
import { Grid, Typography } from "@mui/material"
import SectionTitle from "../../components/section-title/section-title.component"
import parse from "html-react-parser"
import CustomImage from "../../components/custom-image/custom-image.component"

const ImplementationServices = ({
  image,
  title,
  subtitle,
  content,
  bulletPoints,
}) => {
  return (
    <S.Section id="realize">
      <S.CustomContainer>
        <Grid container spacing={{ xs: 6, md: 12 }} alignItems="center">
          <Grid item md={6}>
            <CustomImage img={image} alt="implementation-services" />
          </Grid>
          <Grid item md={6}>
            <S.Content>
              <SectionTitle>{title}</SectionTitle>
              <S.Subtitle>{subtitle}</S.Subtitle>
              <S.Paragraph>{content}</S.Paragraph>
              {parse(bulletPoints)}
            </S.Content>
          </Grid>
        </Grid>
      </S.CustomContainer>
    </S.Section>
  )
}

export default ImplementationServices
