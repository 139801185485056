import styled from "@emotion/styled"
import { Container, Typography } from "@mui/material"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: #f3f3f3;
  //padding-top: 5em;
  position: relative;
  p {
    font-family: ${({ theme }) => theme.fonts.secondary};
  }
  padding-bottom: 8rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-bottom: 15em;
  }
`

export const TopTitle = styled(Typography)`
  font-size: 0.875rem;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: 600;
  font-family: ${({ theme }) => theme.fonts.secondary};
`

export const StyledBgImage = styled(CustomBgImage)`
  position: absolute;
  width: 100%;
  left: 0;
  //top: 105px;
  opacity: 0.5;
  top: 0;
  height: 700px;
  object-fit: cover;
`

export const CustomContainer = styled.div`
  /* ${({ theme }) => theme.sectionSpacing}; */
  position: relative;
  overflow: hidden;
  z-index: 1;
`

export const SliderWrapper = styled(Container)`
  margin-top: 2em;
  margin-bottom: 2em;
  //margin: 2em auto auto;

  .slick-list {
    display: flex;
    height: auto !important;
  }

  .slick-track {
    display: flex;
  }
  .slick-slide {
    ${({ theme }) => theme.breakpoints.down("md")} {
      display: flex !important;
    }
  }
  .slick-dots {
    display: block;
    position: relative;
    padding: 0;
    ul {
      padding: 0;
    }

    li {
      width: auto;
      height: auto;

      &.slick-active {
        span {
          background-color: ${({ theme }) => theme.palette.secondary.main};
        }
      }
    }
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    .slick-dots {
      margin-top: 1em;
      text-align: left;
    }
    .slick-list {
      overflow: initial;
      margin-left: -1em;
      margin-right: -1em;
    }
  }
`

export const Controls = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  margin-top: 1em;
  svg {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
`

export const SliderButton = styled.button`
  height: 48px;
  width: 48px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;

  &:focus {
    outline: none;
  }

  &.prev {
    background-color: white;
  }

  &.next {
    margin-left: 1em;
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`

export const CustomDot = styled.span`
  width: 30px;
  height: 3px;
  display: block;
  padding: 0 0.5em;
  background-color: rgba(48, 48, 48, 0.16);
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 48px;
  }
`
