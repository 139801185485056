import React from "react"
import * as S from "./home-hero.styles"
// import Container from "@material-ui/core/Container";
// import Typography from "@material-ui/core/Typography";
// import IntroSection from "./home-intro/home-intro.component";
// import HomeImplementation from "./home-implementation/home-implementation.component";
// import HomeLead from "./home-lead/home-lead.component";
// import HomeAbout from "./home-about/home-about.component";
// import ThemeButton from "../../components/theme-button/theme-button.component"
// import {getACFFields} from "../../utils.async";
// import Spinner from "../../components/spinner/spinner.component"
import Video from "../../assets/hero-bg-video.mp4"
import HeroSlider from "../../components/hero-slider/hero-slider.component"

const HomeHero = ({ title, sliderItems }) => {
  //   ReactGA.pageview("/")
  //   console.log("sliderItems :>> ", sliderItems)
  //   const [acfFields, setAcfFields] = useState(null)
  //   const matches = useMediaQuery(theme => theme.breakpoints.up("md"))
  //   useEffect(() => {
  //     getACFFields(6).then(fields => setAcfFields(fields))
  //   }, [])

  //   if (!acfFields) return <Spinner />

  // const parse = require("html-react-parser")

  // const {
  //     hero_section,
  //     what_we_do_section,
  //     advisory_services_section,
  //     implementation_services_section,
  //     interim_leadership_section,
  //     about_us_section
  // } = acfFields;

  //   const Scroll = require("react-scroll")
  //   const scroller = Scroll.scroller
  //   const handleScrollTo = () => {
  //     scroller.scrollTo("contact", {
  //       smooth: true,
  //       // offset: 50, // Scrolls to element + 50 pixels down the page
  //     })
  //   }

  return (
    <>
      <S.Wrapper id="home-hero">
        <S.VideoBg playsInline autoPlay muted loop id="myVideo">
          <source src={Video} type="video/mp4" />
        </S.VideoBg>
        <S.HeroTextContainer>
          <S.Title component="h1">{title}</S.Title>
        </S.HeroTextContainer>
      </S.Wrapper>
      <HeroSlider sliderItems={sliderItems} />
    </>
  )
}

export default HomeHero
