import React, { useState } from "react"

import * as S from "./advise-card.styles"
import { Box, Typography } from "@mui/material"
import parse from "html-react-parser"
import CustomImage from "../custom-image/custom-image.component"

const AdviseCard = ({ title, description, onHoverText, image }) => {
  const [showDetail, setShowDetail] = useState(false)

  return (
    <S.ServiceCard
      onMouseEnter={() => setShowDetail(true)}
      onMouseLeave={() => setShowDetail(false)}
    >
      <Box display={{ xs: "none", md: "block" }} position={"relative"}>
        <S.Overlay />
        {!showDetail ? (
          <S.Overview>
            <Typography component="h3">{title}</Typography>
            <Typography>{description}</Typography>
          </S.Overview>
        ) : (
          <S.AdviseCardContent>
            <S.CardTitle>{title}</S.CardTitle>
            <Typography>{description}</Typography>
            {parse(onHoverText)}
          </S.AdviseCardContent>
        )}
        <CustomImage img={image} alt={title} arPaddingPercentage={70} />
      </Box>
      <Box display={{ md: "none" }} height="100%">
        <CustomImage img={image} alt={title} arPaddingPercentage={50} />
        <S.AdviseCardContent>
          <S.CardTitle>{title}</S.CardTitle>
          <Typography>{description}</Typography>
          {parse(onHoverText)}
        </S.AdviseCardContent>
      </Box>
    </S.ServiceCard>
  )
}

export default AdviseCard
