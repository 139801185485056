import { Grid } from "@mui/material"
import Slider from "react-slick"
import styled from "@emotion/styled"

export const CustomSlider = styled(Slider)`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none !important;
  }
`

export const BottomItemsWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  padding-bottom: 5em;
  width: 100%;
  color: white;
  .slick-dots {
    li button:before {
      color: white !important;
    }
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-bottom: 2em;
  }
`

export const GridContainer = styled(Grid)`
  padding: 1em 0;
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`

export const GridItem = styled(Grid)`
  svg,
  img {
    margin-bottom: 1em;
  }

  p {
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.secondary};
    max-width: 220px;
  }
`

export const Slide = styled.div`
  svg,
  img {
    margin-bottom: 1em;
  }
  p {
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.secondary};
  }
`
