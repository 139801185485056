import styled from "@emotion/styled"
import { Typography, Grid } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  color: white;
  p {
    font-family: ${({ theme }) => theme.fonts.secondary};
  }
`

export const IntroGrid = styled(Grid)`
  margin-bottom: 5em;

  h2 {
    &:before {
      content: "";
      position: relative;
      background-color: #979797;
      height: 1px;
      display: block;
      width: 265px;
      margin-bottom: 0.3em;
    }
  }
`

export const ImageColumn = styled(CustomImage)`
  height: 100%;
`

export const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: 4em;
  height: 100%;
  &:after {
    content: "";
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    height: 98px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
  }

  p {
    color: #cdcdcd;
  }
`

export const ImageCaption = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 2em;
  left: 2em;
`

export const InfoTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.6em;
`

export const InfoContent = styled(Typography)`
  color: #7c7c7c;
  min-height: 72px;
`

export const Highlights = styled(Typography)`
  color: #cdcdcd;
  font-size: 1.5rem;
`
