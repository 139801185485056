import React, { useRef } from "react"
import * as S from "./hero-slider.styles"
import parse from "html-react-parser"
import CustomImage from "../custom-image/custom-image.component"
import { Container } from "@mui/material"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const HeroSlider = ({ sliderItems }) => {
  let slider = useRef(null)

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    // variableWidth: true,
    arrows: false,
    dots: true,
    autoPlay: true,
    autoPlaySpeed: 5000,
  }
  return (
    <S.BottomItemsWrapper>
      <Container>
        {sliderItems && (
          <>
            <S.GridContainer container spacing={10}>
              {sliderItems.map(({ icon, description }, index) => (
                <S.GridItem item xs={12} md={4} key={`slide-item-${index}`}>
                  <CustomImage img={icon} />
                  {description && parse(description)}
                </S.GridItem>
              ))}
            </S.GridContainer>
            <S.CustomSlider ref={c => (slider = c)} {...settings}>
              {sliderItems?.map(({ icon, description }, index) => (
                <S.Slide key={`slide-${index}`}>
                  <CustomImage img={icon} />
                  {description && parse(description)}
                </S.Slide>
              ))}
            </S.CustomSlider>
          </>
        )}
      </Container>
    </S.BottomItemsWrapper>
  )
}

export default HeroSlider
