import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { getPageLayout } from "../../utils/get-layout-utils"

export const query = graphql`
  query PageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      isFrontPage
      title
      content
      uri
      slug
      parentId
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      pageBuilder {
        layouts {
          ... on WpPage_Pagebuilder_Layouts_MiscContent {
            ...MiscContent
          }
          ... on WpPage_Pagebuilder_Layouts_HomeHero {
            ...HomeHero
          }
          ... on WpPage_Pagebuilder_Layouts_AdvisoryServices {
            ...AdvisoryServices
          }
          ... on WpPage_Pagebuilder_Layouts_ImplementationServices {
            ...ImplementationServices
          }
          ... on WpPage_Pagebuilder_Layouts_InterimLeadership {
            ...InterimLeadership
          }
          ... on WpPage_Pagebuilder_Layouts_AboutUs {
            ...AboutUs
          }
        }
        pageConfiguration {
          hideFooter
          hideHeaderItems
          footerFields {
            copyrightText
            facebookLink
            title
            instagramLink
            linkedinLinks {
              fieldGroupName
              linkedinLink
              phone {
                title
                url
              }
            }
            twitterLink
          }
        }
      }
    }
  }
`
const PageTemplate = ({ data }) => {
  const { seo, slug, pageBuilder, title } = data.wpPage
  const layouts = pageBuilder.layouts || []
  return (
    <Layout {...pageBuilder.pageConfiguration} seo={seo}>
      {layouts.map(layout => getPageLayout(layout))}
    </Layout>
  )
}

export default PageTemplate
