import React from "react"
import MiscContent from "../layouts/MiscContent"
import HomeHero from "../layouts/HomeHero"
import AdvisoryServices from "../layouts/AdvisoryServices"
import ImplementationServices from "../layouts/ImplementationServices"
import InterimLeadership from "../layouts/InterimLeadership"
import AboutUs from "../layouts/AboutUs/about-us.component"

export const getPageLayout = layout => {
  const layoutName = layout?.fieldGroupName?.split("_").pop().trim()
  if (!layoutName) return null
  switch (layoutName) {
    case "MiscContent":
      return <MiscContent {...layout} />
    case "HomeHero":
      return <HomeHero {...layout} />
    case "AdvisoryServices":
      return <AdvisoryServices {...layout} />
    case "ImplementationServices":
      return <ImplementationServices {...layout} />
    case "InterimLeadership":
      return <InterimLeadership {...layout} />
    case "AboutUs":
      return <AboutUs {...layout} />
  }
}
