import styled from "@emotion/styled"
import { Box, Typography } from "@mui/material"
import CustomImage from "../custom-image/custom-image.component"

export const ServiceCard = styled.div`
  //max-width: 362px;
  padding: 0 1em;
  //min-height: 400px;
  height: 100%;
  position: relative;
`

export const CardTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5em;
`

export const AdviseCardContent = styled.div`
  position: relative;
  z-index: 2;
  background-color: white;
  padding: 1.5em;
  height: 100%;
  overflow: hidden;
  ${({ theme }) => theme.breakpoints.up("md")} {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2em;
    right: 0;
    bottom: 0;
    height: auto;
    min-height: unset;
  }

  p,
  li {
    font-size: 0.875rem;
  }
`

export const Overview = styled.div`
  position: absolute;
  left: 1.5em;
  bottom: 1.5em;
  right: 0;
  z-index: 2;
  color: white;

  h3 {
    margin-bottom: 1em;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: ${({ theme }) => theme.fonts.secondary};
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 100%;
  height: 100%; */
  background-color: black;
  z-index: 1;
  opacity: 0.4;
`
