import styled from "@emotion/styled"
import { Container, Grid } from "@mui/material"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  color: white;
  position: relative;
`

export const StyledBgImage = styled(CustomBgImage)`
  width: 100%;
  height: 600px;
  position: absolute;
  top: 0;
  img {
    object-position: 52%;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 200px;
    width: 100%;
    background: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111111 100%);
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 600px;
    img {
      object-position: center;
    }
  }
`
export const IntroGrid = styled(Grid)`
  margin-bottom: 2em;
  color: white;
  font-size: 0.875rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: 9em;
  }
  h2 {
    color: white;
  }
  p {
    font-family: ${({ theme }) => theme.fonts.primary};
  }
`

export const CustomContainer = styled(Container)`
  position: relative;
  z-index: 1;
  p {
    font-family: ${({ theme }) => theme.fonts.secondary};
  }
`

export const DarkBox = styled.div`
  border-radius: 4px;
  background-color: #222323;
  padding: 1.5em;
  min-height: 180px;
  color: white;
  svg {
    margin-bottom: 1em;
  }
`
